// This only shows up when there is a relevantCohort
import { setupBrandNameProperties, setupStyleHelpers } from 'AppBranding';
import {
    acceptedAdmissionOffer,
    earlyRegistrationDeadlineMs,
    inEarlyAdmissionsRegistrationPeriod,
} from 'AdmissionOffer';
import {
    getAoiRecord,
    getReapplicationDate,
    getCanEventuallyReapply,
    getHasPendingAdmissionOfferOrIsNotYetCurrent,
    getProgramInclusion,
    getAdmissionOffer,
} from 'Users';
import { AoiRecordType, isAdmissionOffer, isProgramApplication, isProgramInclusion } from 'ProgramAoi';
import { graduatedOrWillNotGraduate, hasBeenExpelled, hasFailed, willNotGraduate, isIncluded } from 'ProgramInclusion';
import { isPendingProgramApplication, isRejectedProgramApplication } from 'ProgramApplication';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { ProgramFamilyConfigs } from 'Program/ProgramFamilyConfigs';
import { ProgramTypeConfigs } from 'Program';
import template from '../../views/stream/student_dashboard_program_box.html';
import angularModule from '../lessons_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentDashboardProgramBox', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const CertificateHelperMixin = $injector.get('Stream.CertificateHelperMixin');
        const Cohort = $injector.get('Cohort');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('lessons.stream.student_dashboard_program_box');
        const dateHelper = $injector.get('dateHelper');
        const isMobileMixin = $injector.get('isMobileMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                currentUser: '<',
                foundationPlaylist: '<',
                numPlaylists: '<',
                showAcceptedMessage: '<',
            },
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                CertificateHelperMixin.onLink(scope);
                isMobileMixin.onLink(scope);
                scope.dateHelper = dateHelper;

                //------------------------
                // Branding
                //------------------------

                const brandingOpts = { branding: () => scope.currentUser?.relevantCohort?.branding };
                setupBrandNameProperties($injector, scope, brandingOpts);
                setupStyleHelpers($injector, scope, brandingOpts);

                //---------------------------
                // Action buttons
                //---------------------------

                scope.onClickMobile = () => {
                    if (scope.canExpandToViewStatus) {
                        scope.toggleMobileExpanded();
                    } else if (scope.showApplyButton) {
                        scope.applyToMBA();
                    } else if (scope.showRegisterButton) {
                        scope.gotoMbaSettings();
                    }
                };

                scope.mobileExpanded = false;
                scope.toggleMobileExpanded = () => {
                    scope.mobileExpanded = !scope.mobileExpanded;
                };

                scope.gotoMbaSettings = () => {
                    // If the user can submit an application or they have a pending application they can
                    // edit, send them to the application form.
                    if (
                        scope.currentUser.canCurrentlySubmitProgramApplication ||
                        scope.currentUser.hasPendingProgramApplication
                    ) {
                        scope.loadRoute('/settings/application');
                    } else {
                        scope.loadRoute('/settings/application_status');
                    }
                };

                scope.applyToMBA = () => {
                    scope.loadRoute('/settings/application');
                };

                scope.getActiveProgramInclusion = getProgramInclusion;

                //----------------------------
                // Watches
                //----------------------------

                // eslint-disable-next-line complexity
                function setProgramBoxStatus() {
                    if (!scope.currentUser) return;

                    // We have a standardized structure for the translations to generate the appropriate subtitle.
                    //
                    // Components (applicable statuses):
                    // - program_type: self explanatory
                    // - status: status of applicable AOI record mapped to an old CA status, or 'not_applied'
                    // - not_started? (accepted): whether the user has just been accepted and not started yet
                    // - foundations_complete? (pending, accepted): whether the user has finished the foundations playlist
                    // - no_reapply_cta? (rejected): whether the user should see a reapply call-to-action
                    //
                    // For EMBA and MBA, we also add the following statuses:
                    // - status: include 'pre_accepted' as a possible status
                    // - registered? (pre_accepted): whether the user is registered for the class or not
                    //   - For registered, we add `program_guide` if the cohort has a Program Guide
                    //
                    // from these components, generate the appropriate locale string. See the _spec for this directive
                    // for a breakout of all of the locale keys that are applicable to a given program_type.

                    const program_type = scope.currentUser.programType;
                    const relevantCohort = scope.currentUser.relevantCohort;
                    const programTypeConfig = ProgramTypeConfigs[program_type];
                    const programFamilyConfig = ProgramFamilyConfigs[programTypeConfig?.programFamily];

                    // FIXME: This function serves to map AOI statuses to their roughly equal CA status
                    // When this box is migrated as part of
                    // https://trello.com/c/w7UBwfW8/272-5-chore-migrate-remaining-side-bar-boxes-to-use-the-new-side-bar-box-configs
                    // we should take on the task of replacing this with something like lastActiveAoiRecord(user).status
                    // and update the locale keys accordingly
                    const initialStatus = () => {
                        const aoiRecord = getAoiRecord(scope.currentUser);

                        if (!aoiRecord) return 'not_applied';

                        if (isProgramApplication(aoiRecord)) {
                            if (isRejectedProgramApplication(aoiRecord)) return 'rejected';
                            if (isPendingProgramApplication(aoiRecord)) return 'pending';
                            return 'applied';
                        }

                        if (getHasPendingAdmissionOfferOrIsNotYetCurrent(scope.currentUser)) return 'pre_accepted';

                        if (isAdmissionOffer(aoiRecord)) {
                            if (acceptedAdmissionOffer(aoiRecord)) return 'accepted';
                            return 'rejected';
                        }

                        if (isProgramInclusion(aoiRecord)) {
                            if (willNotGraduate(aoiRecord)) return 'expelled';
                            return 'accepted';
                        }

                        return 'not_applied';
                    };

                    let status = initialStatus();

                    const activeAdmissionOffer = getAoiRecord(scope.currentUser, AoiRecordType.AdmissionOffer);

                    // If the user has recently completed the checkout process on the registration page, but we haven't yet processed the
                    // delayed jobs from stripe, created the program inclusion, and pulled it down into the client, we still show
                    // things here as though the admission offer is already marked as accepted because the user has already done everything
                    // they need to do and, barring some strange error, the admission offer will be marked as accepted withing a minute.
                    const hasAcceptedAdmissionOffer =
                        acceptedAdmissionOffer(activeAdmissionOffer) ||
                        scope.currentUser.completedStripeCheckoutSession;

                    /*
                        special status cases:
                        - if you're accepted to biz cert and have graduated, you get a special status
                            Note: It's not obvious here, but we don't render this dir for MBA / EMBA. See showDesktopProgramBox / showMobileProgramBox.
                        - if you're accepted, even to an "older" application because of monkey business in the admin tools, you should be accepted
                        - if you're in one of several states, collapse the status to 'applied' for this directive's purposes
                        - if you're accepted and have completed all of the required courses
                        - if you're in the EMBA or MBA and pre_accepted
                    */
                    const programInclusionForActiveProgram = getAoiRecord(
                        scope.currentUser,
                        AoiRecordType.ProgramInclusion,
                    );

                    const programAppForActiveProgram = getAoiRecord(
                        scope.currentUser,
                        AoiRecordType.ProgramApplication,
                    );

                    if (
                        graduatedOrWillNotGraduate(programInclusionForActiveProgram) &&
                        !hasBeenExpelled(programInclusionForActiveProgram)
                    ) {
                        // auto-graded cohorts can show a failure message
                        if (hasFailed(programInclusionForActiveProgram) && Cohort.supportsAutograding(program_type)) {
                            status = 'program_failed';
                        } else {
                            status = 'program_complete';
                        }
                    } else if (getHasPendingAdmissionOfferOrIsNotYetCurrent(scope.currentUser)) {
                        status = 'pre_accepted';
                    } else if (isIncluded(programInclusionForActiveProgram)) {
                        status = 'accepted';
                    } else if (isPendingProgramApplication(programAppForActiveProgram)) {
                        status = 'applied';
                    }

                    // Most of the program box locales that reference the program title append the "program" suffix after the title - except for the
                    // "program_complete" case. In that case, we should show the standaloneShortProgramTitle instead of just the shortProgramTitle.
                    // Using an IIFE here to avoid a nested ternary.
                    scope.programTitle =
                        status === 'program_complete'
                            ? relevantCohort.standaloneShortProgramTitle
                            : relevantCohort.shortProgramTitle;

                    const not_started = scope.showAcceptedMessage && status === 'accepted' ? 'not_started' : undefined;
                    const foundations_complete =
                        !!scope.foundationsComplete &&
                        (status === 'applied' || (status === 'accepted' && !!not_started))
                            ? 'foundations_complete'
                            : undefined;

                    let no_reapply_cta;
                    if (relevantCohort.supportsAdmissionRounds) {
                        if (
                            getCanEventuallyReapply(scope.currentUser) &&
                            !scope.currentUser.canCurrentlySubmitProgramApplication
                        ) {
                            no_reapply_cta = 'no_reapply_cta';
                            scope.reapplicationDate = dateHelper.formattedUserFacingMonthDayLong(
                                getReapplicationDate(scope.currentUser),
                                false,
                            );
                        }
                    } else {
                        no_reapply_cta = scope.currentUser.isNotJoiningPromotedCohort ? 'no_reapply_cta' : undefined;
                    }

                    let registered;
                    if (relevantCohort.supportsRegistrationDeadline) {
                        if (status === 'pre_accepted') {
                            if (hasAcceptedAdmissionOffer) {
                                registered = 'registered';
                            } else if (scope.currentUser.relevantCohort.afterRegistrationOpenDate) {
                                registered = 'not_registered';
                            } else {
                                registered = 'not_registered_not_open';
                            }
                        }

                        scope.registrationOpenDate = dateHelper.formattedUserFacingMonthDayLong(
                            scope.currentUser.relevantCohort.registrationOpenDate,
                        );

                        const earlyAdmissionRegistrationDeadline = inEarlyAdmissionsRegistrationPeriod(
                            getAdmissionOffer(scope.currentUser),
                        )
                            ? earlyRegistrationDeadlineMs(getAdmissionOffer(scope.currentUser))
                            : null;

                        const registrationDeadline =
                            earlyAdmissionRegistrationDeadline && Date.now() < earlyAdmissionRegistrationDeadline
                                ? earlyAdmissionRegistrationDeadline
                                : scope.currentUser.relevantCohort.registrationDeadline;

                        scope.registrationDeadline = dateHelper.formattedUserFacingMonthDayLong(registrationDeadline);
                    }

                    if (relevantCohort.supportsAdmissionRounds) {
                        scope.decisionDate = dateHelper.formattedUserFacingMonthDayLong(
                            scope.currentUser.relevantCohort.lastDecisionDate,
                            false,
                        );
                    }

                    // decide whether to show additional UI elements
                    scope.showProgressBar =
                        status === 'applied' && relevantCohort.studentDashboardProgramBoxShowProgressBar;
                    scope.showApplyButton = scope.currentUser.canCurrentlySubmitProgramApplication;
                    scope.showRegisterButton =
                        status === 'pre_accepted' &&
                        relevantCohort.supportsPayments &&
                        !hasAcceptedAdmissionOffer &&
                        relevantCohort.afterRegistrationOpenDate;
                    scope.showCertificateDownloadArea =
                        status === 'program_complete' && Cohort.supportsCertificateDownload(program_type);
                    scope.showFaqButton =
                        !scope.showCertificateDownloadArea && (status === 'accepted' || status === 'program_complete');

                    // whether to make it taller
                    scope.taller =
                        scope.showProgressBar ||
                        scope.showApplyButton ||
                        scope.showCertificateDownloadArea ||
                        scope.showRegisterButton;

                    // construct the locale keys
                    scope.mobileStatusKey = ['mobile', 'status', status, no_reapply_cta, registered]
                        .filter(Boolean)
                        .join('_');

                    if (status === 'not_applied') {
                        scope.titleHtml = translationHelper.get('apply_to', {
                            brandName: scope.brandNameShort,
                        });
                        scope.mobileTitleHtml = translationHelper.get(
                            programFamilyConfig?.programBoxNotAppliedMobileHtmlKey ?? 'mba_programs',
                            {
                                brandName: scope.brandNameShort,
                            },
                        );
                        scope.subtitleKey = 'subtitle_not_applied';
                        if (scope.currentUser?.isNominee) {
                            scope.subtitleKey = 'subtitle_not_applied_nominee';
                        }
                    } else if (status === 'pre_accepted') {
                        scope.titleHtml = relevantCohort.studentDashboardProgramBoxTitleHtml;
                        // We only show a program-guide variant if the user is registered
                        const programGuide =
                            registered === 'registered' && relevantCohort.programGuideUrl ? 'program_guide' : undefined;
                        const noOrientation =
                            registered === 'registered' && !scope.currentUser?.relevantCohort?.hasOrientation
                                ? 'no_orientation'
                                : undefined;
                        scope.subtitleKey = ['subtitle', registered, noOrientation, programGuide]
                            .filter(Boolean)
                            .join('_');
                    } else {
                        scope.titleHtml =
                            status === 'applied'
                                ? translationHelper.get('application_received')
                                : relevantCohort.studentDashboardProgramBoxTitleHtml;

                        scope.mobileTitleHtml = relevantCohort.studentDashboardProgramBoxTitleHtml;
                        scope.subtitleKey = [
                            'subtitle',
                            // Users who opt in to early admissions shouldn't see the final decision date included in the messaging here,
                            // so we check for that and show the "generic" subtitle (which excludes the date) instead.
                            status === 'applied' && programAppForActiveProgram.earlyAdmissionOptIn
                                ? 'generic'
                                : relevantCohort.studentDashboardProgramBoxSubtitleKey,
                            status,
                            not_started,
                            foundations_complete,
                            no_reapply_cta,
                            registered,
                        ]
                            .filter(Boolean)
                            .join('_');
                    }

                    // In the following cases we want to allow users to expand this box
                    // on mobile to get some extra information as to why there's not a current
                    // call-to-action for them.
                    scope.canExpandToViewStatus =
                        no_reapply_cta || registered === 'not_registered_not_open' || scope.showCertificateDownloadArea;

                    // used to decide whether to show special status styling on mobile
                    scope.rejected = ['rejected', 'expelled', 'program_failed', 'rejected_after_pre_accepted'].includes(
                        status,
                    );
                    scope.pre_accepted = status === 'pre_accepted';

                    scope.showPreAcceptedMessage =
                        scope.pre_accepted && !scope.showRegisterButton && !!scope.mobileStatusKey;

                    // whether to align sub-title to the right
                    scope.noRight =
                        !scope.showCertificateDownloadArea &&
                        !scope.showApplyButton &&
                        !scope.showFaqButton &&
                        !scope.showRegisterButton &&
                        !scope.showProgressBar &&
                        !scope.rejected;

                    // used in some CSS styling
                    scope.programType = program_type;
                }

                scope.$watch('mobileExpanded', () => {
                    scope.mobileCanViewStatusLocaleKey = scope.mobileExpanded
                        ? 'lessons.stream.student_dashboard_program_box.close'
                        : 'lessons.stream.student_dashboard_program_box.view_status';
                });

                scope.$watch(() => {
                    const value = getAoiRecord(scope.currentUser)?.status;
                    return value;
                }, setProgramBoxStatus);

                // canCalculateComplete will be false until all of the streams in the foundation playlist are loaded.  It is
                // a little expensive to calculate, and once it's true it should always be true, so stop watching for it once it is true
                scope.foundationsComplete = null;
                const stopWatchingCanCalculateComplete = scope.$watch(
                    'foundationPlaylist.canCalculateComplete',
                    canCalculateComplete => {
                        if (canCalculateComplete) {
                            scope.foundationsComplete = scope.foundationPlaylist.complete;
                            setProgramBoxStatus();
                            stopWatchingCanCalculateComplete();
                        }
                    },
                );
            },
        };
    },
]);
