import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/stream/bookmarked_completed_courses.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import toggleableCourseListButtonsTemplate from '../../views/stream/toggleable_course_list_buttons.html';
import coursesFlatTemplate from '../../views/stream/student_dashboard_courses_flat.html';
import coursesTopicTemplate from '../../views/stream/student_dashboard_courses_topic.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('bookmarkedCompletedCourses', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const HasToggleableDisplayMode = $injector.get('HasToggleableDisplayMode');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const offlineModeManager = $injector.get('offlineModeManager');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                HasToggleableDisplayMode.onLink(scope, 'toggleableCourseListStudentDashboard', true, true);
                // HasToggleableDisplayMode controls DOM pre-rendering
                scope.preloadAllDisplayModes();

                scope.coursesFlatTemplate = coursesFlatTemplate;
                scope.coursesTopicTemplate = coursesTopicTemplate;
                scope.offlineModeManager = offlineModeManager;
                scope.toggleableCourseListButtonsTemplate = toggleableCourseListButtonsTemplate;

                scope.browseTopic = topicName => {
                    RouteAnimationHelper.animatePathChange(`/courses?topics[]=${topicName}`, 'slide-left');
                };

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // determine if user in the special blue ocean high school group
                // FIXME: there are 512 non-deactivated users on prod with the BOSHIGH sign_up_code.
                // I am not entirely sure why we wouldn't want to show them the toggleable course buttons
                // here, but this logic used to exist in student_dashboard_dir.js so it got ported over here.
                // We should probably revisit this and showToggleableCourseButtons below.
                Object.defineProperty(scope, 'blueOceanHighSchooler', {
                    get() {
                        return scope.currentUser?.sign_up_code === 'BOSHIGH';
                    },
                });

                // whether to show the course little toggle button
                Object.defineProperty(scope, 'showToggleableCourseButtons', {
                    get() {
                        return !scope.blueOceanHighSchooler;
                    },
                });

                const getBookmarkedAndCompletedStreams = () => {
                    LearnerContentCache.ensureStudentDashboard().then(response => {
                        const { lesson_streams } = response.result[0];

                        if (!lesson_streams?.length > 0) return;

                        const sortedStreams = lesson_streams.sort(stream => stream.title);
                        // Bookmarked courses are sorted by title by default, but can ge optionally grouped by topic.
                        // See HasToggleableDisplayMode / createTopicGroups.
                        scope.bookmarkedCourses = sortedStreams.filter(
                            stream => stream.favorite && stream.progressStatus() !== 'completed',
                        );
                        scope.createTopicGroups(scope.bookmarkedCourses);
                        // Completed courses are sorted by last progress date desc by default.
                        scope.completedCourses = sortedStreams
                            .filter(stream => stream.progressStatus() === 'completed')
                            .sort(stream => stream.lastProgressAt)
                            .reverse();
                    });
                };

                scope.$watch('currentUser', currentUser => {
                    if (currentUser) getBookmarkedAndCompletedStreams();
                });
            },
        };
    },
]);
