import { type ChallengesFrame, type ComponentizedInstance } from './Componentized.types';
import { type FrameIguanaObject } from './Lessons.types';

export function isChallengesFrame(frame: ComponentizedInstance): frame is ChallengesFrame {
    return frame.mainUiComponent.component_type === 'ComponentizedFrame.Challenges';
}

export function isComponentized(frame: FrameIguanaObject): frame is ComponentizedInstance {
    return frame.frame_type === 'componentized';
}

export function ensureComponentized(frame: FrameIguanaObject): ComponentizedInstance {
    if (!isComponentized(frame)) {
        throw new Error('Expected a componentized frame');
    }
    return frame;
}
